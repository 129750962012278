import React from 'react';
import Layout from '../../components/layout';
import Header from '../../components/Header';
import SubPages from '../../components/Subpages';
import PageContainer from '../../components/Containers/PageContainer';
import Index from '../../components/Contact/Index';
import JobOffers from '../../components/Contact/JobOffers';
//import RequestSupport from '../../components/Contact/RequestSupport';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core';
import SIZES from '../../constants/sizes';
import COLORS from '../../constants/colors';
import SEO from '../../components/seo';
import NAV_ITEMS from '../../constants/nav-items';

const subnavItems = NAV_ITEMS[5].subpages;

const useStyles = makeStyles(theme => ({
  title: {
    color: COLORS.DARK_BLUE,
    marginLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT,
    [theme.breakpoints.down('md')]: {
      marginLeft: SIZES.PADDING.LEFT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: SIZES.PADDING.LEFT_PADDING.XS,
    },
  },
}));

const Contact = () => {
  const classes = useStyles();
  const SUBPAGES_CONTENTS = [
    Index,
    JobOffers,
    //RequestSupport,
  ];
  return (
    <Layout>
      <SEO
        title="Scanship | Contact"
        description="Contact Scanship offices"
        keywords={['Scanship']}
      />
      <PageContainer>
        <Header
          sub
          textTransform="lowerCase"
          className={classes.title}
        >
          <FormattedMessage id="contact.title" />
        </Header>
        <SubPages
          navItems={subnavItems}
          contents={SUBPAGES_CONTENTS}
        />
      </PageContainer>
    </Layout>
  );
};

export default Contact;
