import React from 'react';
import PageSection from '../Cards/PageSection';
import { Grid, makeStyles } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import MailingForm from '../Forms/MailingForm';
import EmployeesSection from '../Cards/EmployeesSection';
import ContactSection from '../Cards/ContactSection';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/sizes';
import { useIntl } from 'gatsby-plugin-intl';
import { FormattedMessage } from 'gatsby-plugin-intl';

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginTop: 98,
    [theme.breakpoints.down('xs')]: {
      marginTop: 80,
    },
  },
  title: {
    fontWeight: FONTS.WEIGHT.BOLD,
    fontSize: FONTS.SIZE.XXM,
    marginTop: 98,
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
    },
  },
  EmployeesSection: {
    color: COLORS.DARK_BLUE,
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD + 55,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
  },
}));

const Index = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const data = useStaticQuery(query);
  let isActualPagePostReversed = false;

  // console.log(data.allContentfulScanshipContactSupport.edges[0].node);

  return (
    <Grid container>
      {data.allContentfulScanshipPageSection.edges.map(
        (
          {
            node: {
              image,
              title,
              description: { description },
            },
          },
          i
        ) => {
          isActualPagePostReversed = !isActualPagePostReversed;
          return (
            <PageSection
              key={title}
              imgFluid={image && image.fluid}
              title={title}
              description={description}
              reversed={isActualPagePostReversed}
              paddingTop={i !== 0}
              injectedElement={
                i ===
                  data.allContentfulScanshipPageSection
                    .edges.length -
                    1 && (
                  <Grid
                    container
                    className={classes.formContainer}
                  >
                    <MailingForm
                      name="contact"
                      title={
                        <FormattedMessage id="JobOffers.defaultTitle" />
                      }
                    />
                  </Grid>
                )
              }
            />
          );
        }
      )}
      <div className={classes.EmployeesSection}>
        <h2 className={classes.title}>
          {formatMessage({
            id: 'contact.employeeSections.1.name',
          })}
        </h2>
      </div>
      <ContactSection
        data={
          data.allContentfulScanshipContactSupport.edges[0]
            .node
        }
      />
      <EmployeesSection data={data.dep2.edges} />
      <EmployeesSection
        department={formatMessage({
          id: 'contact.employeeSections.0.name',
        })}
        data={data.dep1.edges}
      />
    </Grid>
  );
};

export default Index;

const query = graphql`
  query ContactQuery {
    allContentfulScanshipPageSection(
      filter: { page: { eq: "/contact" } }
      sort: { fields: createdAt, order: ASC }
    ) {
      edges {
        node {
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          title
          description {
            description
          }
        }
      }
    }
    dep1: allContentfulScanshipEmployees(
      filter: {
        department: { eq: "Sales and Technical inquiries" }
      }
    ) {
      edges {
        node {
          name
          title
          phone
          mobile
          email
          department
        }
      }
    }
    dep2: allContentfulScanshipEmployees(
      filter: {
        department: {
          eq: "Sales Services, Parts & Chemicals"
        }
      }
    ) {
      edges {
        node {
          name
          title
          phone
          mobile
          email
          department
        }
      }
    }
    allContentfulScanshipContactSupport(
      filter: { name: { eq: "Scanship support contacts" } }
    ) {
      edges {
        node {
          chemicalsEmail
          documentationsEmail
          sparesEmail
          serviceEmail
        }
      }
    }
  }
`;
