import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { string, bool } from 'prop-types';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';

const useStyle = makeStyles({
  root: {
    color: COLORS.LIGHT_GREY,
    fontWeight: FONTS.WEIGHT.REGULAR,
    lineHeight: 2.5,
    textTransform: ({ upperCase }) =>
      upperCase ? 'uppercase' : '',
    '& >span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  },
  wrapper: { position: 'relative' },
  input: {
    width: '100%',
    height: 35,
    '&>*': {
      height: '100% !important',
    },
    '& .MuiSelect-outlined': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiOutlinedInput-inputMultiline': {
      overflowY: 'auto !important',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      '& fieldset': {
        borderColor: COLORS.DARK_BLUE,
        border: '1.8px solid',
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.BLUE,
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.RED,
    },
    '& .MuiInputBase-input': {
      height: '100% !important',
      color: COLORS.DARK_BLUE,
      zIndex: 2,
    },
  },
  helper: {
    color: COLORS.RED,
    position: 'absolute',
    top: -13,
    left: 5,
    fontSize: 10,
    backgroundColor: COLORS.WHITE,
    zIndex: 1,
  },
});

const OutlinedInput = props => {
  const {
    label,
    className,
    helperText,
    upperCase,
    error,
  } = props;
  const classes = useStyle({ upperCase });
  const passedProps = { ...props };
  delete passedProps.upperCase;
  delete passedProps.label;
  delete passedProps.helperText;

  return (
    <label className={classes.root}>
      <span>{label}</span>
      <div className={classes.wrapper}>
        {error && helperText && (
          <span className={classes.helper}>
            {helperText}
          </span>
        )}
        <TextField
          variant="outlined"
          {...passedProps}
          className={`${classes.input} ${className}`}
        />
      </div>
    </label>
  );
};

OutlinedInput.propTypes = {
  label: string,
  className: string,
  helperText: string,
  upperCase: bool,
  error: bool,
};

OutlinedInput.defaultProps = {
  label: '',
  className: '',
  helperText: '',
  upperCase: true,
  error: false,
};

export default OutlinedInput;
