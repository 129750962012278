import React, { useState, useRef } from 'react';
import Attachment from '../Attachment';
import { Grid, makeStyles } from '@material-ui/core';
import COLORS from '../../constants/colors';
import {
  string,
  func,
  oneOfType,
  arrayOf,
  element,
  number,
} from 'prop-types';

const useStyles = makeStyles({
  FileInput: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& label': {
      cursor: 'pointer',
    },
    '& input': {
      display: 'none',
    },
    '& svg': {},
    '& span': {
      display: 'inline-flex',
      color: COLORS.BLUE,
      textDecoration: 'underline',
      marginLeft: 20,
    },
  },
  hiddenTrigger: { display: 'none' },
});

const FileInput = ({
  customTrigger,
  onChange,
  className,
  maxSize,
  accept,
  ...restProp
}) => {
  const classes = useStyles();
  const inputRef = useRef();
  const [file, setFile] = useState(null);

  const handleChange = e => {
    const {
      target: { files },
    } = e;
    const { size, name } = files[0];

    if (!maxSize || size <= 1000 * maxSize) {
      if (
        !accept ||
        accept
          .split(',')
          .includes(
            name.slice(name.lastIndexOf('.'), name.length)
          )
      ) {
        setFile(files[0]);
        onChange(e);
      }
    }
    //TODO:Implement Modal shows error message
  };

  const handleDelete = () => {
    setFile(null);
    inputRef.current.value = '';
  };

  return (
    <Grid
      item
      className={`${classes.FileInput} ${className}`}
    >
      <label className={file && classes.hiddenTrigger}>
        <input
          type="file"
          ref={inputRef}
          onChange={handleChange}
          accept={accept}
          {...restProp}
          multiple={false}
        />
        {customTrigger ? (
          customTrigger
        ) : (
          <Attachment asAddFileTrigger />
        )}
      </label>
      {file && (
        <Attachment
          key={file.name}
          name={file.name}
          onClick={handleDelete}
        />
      )}
    </Grid>
  );
};

FileInput.propTypes = {
  customTrigger: oneOfType([element, arrayOf(element)]),
  onChange: func,
  className: string,
  maxSize: number,
  accept: string,
};

FileInput.defaultProps = {
  className: '',
  maxSize: 0,
};

export default FileInput;
