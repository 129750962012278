import React from 'react';
import {
  makeStyles,
  Modal,
  Paper,
  Slide,
} from '@material-ui/core';
import {
  bool,
  string,
  oneOf,
  element,
  oneOfType,
} from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LoopIcon from '@material-ui/icons/Loop';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';

const useStyles = makeStyles({
  ResponseBox: {
    maxHeight: 'fit-content',
  },
  Paper: {
    width: 500,
    maxWidth: '95%',
    margin: 'auto',
    border: '2px solid',
    borderTop: 0,
    outline: 'none',
    padding: 20,
    backgroundColor: 'rgba(248,248,248,0.8)',
    backdropFilter: 'blur(3px)',
    display: 'flex',
    justifyContent: 'space-between',
    userSelect: 'none',
    color: COLORS.DARK_BLUE,
    fontSize: FONTS.SIZE.S,
    fontWeight: FONTS.WEIGHT.MEDIUM,
    borderColor: ({ status }) =>
      status === 'success'
        ? COLORS.GREEN
        : status === 'fail'
        ? COLORS.RED
        : COLORS.YELLOW,
  },
  iconContainer: {
    display: 'inline-block',
    color: ({ status }) =>
      status === 'success'
        ? COLORS.GREEN
        : status === 'fail'
        ? COLORS.RED
        : COLORS.YELLOW,
  },
  animated: {
    animation: 'rotate 4s linear infinite',
  },
});

const ResponseBox = ({ visible, status, text }) => {
  const classes = useStyles({ status });

  return (
    <Modal
      open={visible}
      hideBackdrop
      disableScrollLock
      className={classes.ResponseBox}
      disableAutoFocus
      disableEnforceFocus
    >
      <Slide in={visible}>
        <Paper
          className={classes.Paper}
          elevation={3}
          square
        >
          {text}
          <div className={classes.iconContainer}>
            {status === 'success' ? (
              <CheckCircleOutlineIcon />
            ) : status === 'fail' ? (
              <HighlightOffIcon />
            ) : (
              <LoopIcon className={classes.animated} />
            )}
          </div>
        </Paper>
      </Slide>
    </Modal>
  );
};

ResponseBox.propTypes = {
  visible: bool,
  status: oneOf(['success', 'processing', 'fail']),
  text: oneOfType([string, element]),
};
ResponseBox.defaultProps = {
  visible: false,
};

export default ResponseBox;
