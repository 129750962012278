import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { string } from 'prop-types';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';
import { formatPhoneNumber } from '../../utils/functions';

const useStyles = makeStyles(theme => ({
  EmployeeCardInfoContainer: {
    '&>div:nth-child(1)': {
      fontWeight: FONTS.WEIGHT.BOLD,
      marginBottom: 8,
      fontSize: FONTS.SIZE.XS,
    },
    '&>div:nth-child(2)': {
      marginBottom: 20,
      fontSize: FONTS.SIZE.XS,
    },
    '& a': {
      textDecoration: 'none',
      fontSize: FONTS.SIZE.XXS,
      fontWeight: FONTS.WEIGHT.MEDIUM,
      '&:hover': { color: COLORS.BLUE },
    },
    '& span': {
      marginRight: 5,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 42,
    },
  },
  email: {
    color: COLORS.BLUE,
    '&:hover': { color: `${COLORS.DARK_BLUE} !important` },
  },
}));

const EmployeeCard = ({
  name,
  title,
  phone,
  mobile,
  email,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <Grid item {...restProps}>
      <Grid
        container
        className={classes.EmployeeCardInfoContainer}
      >
        <Grid item xs={12}>
          {name}
        </Grid>
        <Grid item xs={12}>
          {title}
        </Grid>
        {mobile && (
          <Grid item xs={12}>
            <a href={`tel:${mobile}`}>
              <span>
                <FormattedMessage id="EmployeeCard.label2" />
                :
              </span>
              {formatPhoneNumber(mobile)}
            </a>
          </Grid>
        )}
        {phone && (
          <Grid item xs={12}>
            <a href={`tel:${phone}`}>
              <span>
                <FormattedMessage id="EmployeeCard.label1" />
                :
              </span>
              {formatPhoneNumber(phone)}
            </a>
          </Grid>
        )}
        <Grid item xs={12}>
          <a
            href={`mailto:${email}`}
            className={classes.email}
          >
            {email}
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

EmployeeCard.propTypes = {
  name: string.isRequired,
  phone: string,
  mobile: string,
  email: string.isRequired,
};

export default EmployeeCard;
