import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import EmployeeCard from './EmployeeCard';
import FONTS from '../../constants/fonts';
import { string, shape, arrayOf } from 'prop-types';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/sizes';

const useStyles = makeStyles(theme => ({
  EmployeesSection: {
    color: COLORS.DARK_BLUE,
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD + 55,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
  },
  title: {
    fontWeight: FONTS.WEIGHT.BOLD,
    fontSize: FONTS.SIZE.XXM,
    marginTop: 98,
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
    },
  },
}));

const EmployeesSection = ({ department, data }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.EmployeesSection}>
      <Grid container>
        {department && (
          <Grid item xs={12} className={classes.title}>
          {department}
        </Grid>
        )}
        <Grid item xs={12}>
          <Grid container>
            {data.map(
              ({
                node: { name, title, phone, mobile, email },
              }) => (
                <Grid item key={name} xs={12} sm={3}>
                  <EmployeeCard
                    name={name}
                    title={title}
                    phone={phone}
                    mobile={mobile}
                    email={email}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
EmployeesSection.propTypes = {
  title: string,
  data: arrayOf(
    shape({
      node: shape({
        name: string.isRequired,
        phone: string,
        mobile: string,
        email: string.isRequired,
      }),
    })
  ),
};

export default EmployeesSection;
