import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import FONTS from '../../constants/fonts';
import { string, shape, arrayOf } from 'prop-types';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/sizes';

const useStyles = makeStyles(theme => ({
  ContactsSection: {
    marginTop: 20,
    marginBottom: 80,
    color: COLORS.DARK_BLUE,
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD + 55,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
  },
  title: {
    fontWeight: FONTS.WEIGHT.BOLD,
    fontSize: FONTS.SIZE.XXM,
    marginTop: 98,
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
    },
  },
  ContactCardInfoContainer: {
    '&>div:nth-child(1)': {
      fontWeight: FONTS.WEIGHT.BOLD,
      marginBottom: 20,
      fontSize: FONTS.SIZE.XS,
    },
    '& a': {
      textDecoration: 'none',
      fontSize: FONTS.SIZE.XXS,
      fontWeight: FONTS.WEIGHT.MEDIUM,
      '&:hover': { color: COLORS.BLUE },
    },
    '& span': {
      marginRight: 5,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 42,
    },
  },
  email: {
    color: COLORS.BLUE,
    '&:hover': { color: `${COLORS.DARK_BLUE} !important` },
  },
}));

const ContactSection = ({ department, data, ...restProps }) => {
  const classes = useStyles();
  const {
    serviceEmail,
    chemicalsEmail,
    documentationsEmail,
    sparesEmail,
  } = data;

  return (
    <Grid container className={classes.ContactsSection}>
      <Grid container>
        {department && (
          <Grid item xs={12} className={classes.title}>
          {department}
        </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <Grid
            container
            className={classes.ContactCardInfoContainer}
          >
            <Grid item xs={12}>
              General Inquiry
            </Grid>
            <Grid item xs={12}>
              <a
                href={`mailto:${documentationsEmail}`}
                className={classes.email}
              >
                {documentationsEmail}
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid
            container
            className={classes.ContactCardInfoContainer}
          >
            <Grid item xs={12}>
              Spares
            </Grid>
            <Grid item xs={12}>
              <a
                href={`mailto:${sparesEmail}`}
                className={classes.email}
              >
                {sparesEmail}
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid
            container
            className={classes.ContactCardInfoContainer}
          >
            <Grid item xs={12}>
              Chemicals
            </Grid>
            <Grid item xs={12}>
              <a
                href={`mailto:${chemicalsEmail}`}
                className={classes.email}
              >
                {chemicalsEmail}
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid
            container
            className={classes.ContactCardInfoContainer}
          >
            <Grid item xs={12}>
              Services
            </Grid>
            <Grid item xs={12}>
              <a
                href={`mailto:${serviceEmail}`}
                className={classes.email}
              >
                {serviceEmail}
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
ContactSection.propTypes = {
  title: string,
  data: shape({
    serviceEmail: string,
    documentationsEmail: string,
    sparesEmail: string,
    chemicalsEmail: string,
  }),
};

export default ContactSection;
