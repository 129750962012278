import React, { useState } from 'react';
import PageSection from '../Cards/PageSection';
import { Grid, makeStyles } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import MailingForm from '../Forms/MailingForm';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileInput from '../Inputs/FileInput';
import { FormattedMessage } from 'gatsby-plugin-intl';

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginTop: 98,
    [theme.breakpoints.down('xs')]: {
      marginTop: 80,
    },
  },
}));

const JobOffers = () => {
  const classes = useStyles();
  const data = useStaticQuery(query);
  let isActualPagePostReversed = false;

  const [state, setState] = useState({
    attachment: {},
  });

  const handleAttachmentChange = ({
    target: { files, name },
  }) => {
    setState({
      ...state,
      [name]: files[0],
    });
  };

  return (
    <Grid container>
      {data.allContentfulScanshipPageSection.edges.map(
        (
          {
            node: {
              image,
              title,
              description: { description },
            },
          },
          i
        ) => {
          isActualPagePostReversed = !isActualPagePostReversed;
          return (
            <PageSection
              key={title}
              imgFluid={image && image.fluid}
              title={title}
              description={description}
              reversed={isActualPagePostReversed}
              paddingTop={i !== 0}
              paddingBottom={
                i !==
                data.allContentfulScanshipPageSection.edges
                  .length -
                  1
              }
              injectedElement={
                i ===
                  data.allContentfulScanshipPageSection
                    .edges.length -
                    1 && (
                  <Grid
                    container
                    className={classes.formContainer}
                  >
                    <MailingForm
                      name="job-offers"
                      extraInputsState={state}
                      title={
                        <FormattedMessage id="JobOffers.applicationTitle" />
                      }
                      extraInputs={
                        <FileInput
                          maxSize={2000}
                          name="attachment"
                          onChange={handleAttachmentChange}
                          accept=".jpg,.png,.pdf,.rar,.zip,.doc,.docx,.xlsx"
                          customTrigger={
                            <>
                              <FormattedMessage id="JobOffers.attachment.emptyMessage" />
                              <span>
                                <FormattedMessage id="JobOffers.attachment.button" />
                                <AttachFileIcon />
                              </span>
                            </>
                          }
                        />
                      }
                    />
                  </Grid>
                )
              }
            />
          );
        }
      )}
    </Grid>
  );
};

export default JobOffers;

const query = graphql`
  query JobOffersQuery {
    allContentfulScanshipPageSection(
      filter: { page: { eq: "/contact/job-offers" } }
      sort: { fields: createdAt, order: ASC }
    ) {
      edges {
        node {
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          title
          description {
            description
          }
        }
      }
    }
  }
`;
