import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import DefaultButton from '../Buttons/DefaultButton';
import {
  useIntl,
  FormattedMessage,
} from 'gatsby-plugin-intl';
import OutlinedInput from '../Inputs/OutlinedInput';
import FONTS from '../../constants/fonts';
import {
  string,
  element,
  arrayOf,
  oneOfType,
  shape,
} from 'prop-types';
import { encode } from '../../utils/functions';
import ResponseBox from '../Modals/ResponseBox';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 32,
    fontSize: FONTS.SIZE.XXM,
    fontWeight: FONTS.WEIGHT.BOLD,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  input: {
    height: 40,
    marginBottom: 32,
  },
  message: {
    height: 200,
  },
  containerSubmit: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const MailingForm = ({
  name,
  extraInputs,
  extraInputsState,
  title,
}) => {
  const { formatMessage } = useIntl();
  const [state, setState] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const [response, setResponse] = useState({
    visible: false,
    status: 'processing',
    text: '',
  });

  const classes = useStyles();

  const handleChange = ({ target }) => {
    const { value, id } = target;
    setState({
      ...state,
      [id]: value,
    });
  };

  //TODO:create a modal that show response
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setResponse({
      visible: true,
      status: 'processing',
      text: (
        <FormattedMessage id="MailingForm.response.processing" />
      ),
    });
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
        ...extraInputsState,
      }),
    })
      .then(() => {
        setResponse({
          visible: true,
          status: 'success',
          text: (
            <FormattedMessage id="MailingForm.response.success" />
          ),
        });
      })
      .catch(error => {
        setResponse({
          visible: true,
          status: 'fail',
          text: (
            <FormattedMessage id="MailingForm.response.fail" />
          ),
        });
      })
      .finally(() =>
        setTimeout(
          () =>
            setResponse({
              ...response,
              visible: false,
              text: '',
            }),
          3000
        )
      );
  };

  return (
    <>
      <ResponseBox
        visible={response.visible}
        status={response.status}
        text={response.text}
      />
      <p className={classes.title}>{title}</p>
      <form
        name={name}
        onSubmit={handleSubmit}
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        className={classes.form}
      >
        <input
          type="hidden"
          name="form-name"
          value={name}
        />
        <OutlinedInput
          id="fullName"
          name="fullName"
          type="text"
          placeholder={formatMessage({
            id: 'MailingForm.input1',
          })}
          onChange={handleChange}
          className={classes.input}
          value={state.fullName}
          required
        />
        <OutlinedInput
          id="email"
          name="email"
          type="email"
          placeholder={formatMessage({
            id: 'MailingForm.input2',
          })}
          onChange={handleChange}
          className={classes.input}
          value={state.email}
          required
        />
        <OutlinedInput
          multiline
          id="message"
          name="message"
          rows="12"
          placeholder={formatMessage({
            id: 'MailingForm.input3',
          })}
          className={classes.message}
          onChange={handleChange}
          value={state.message}
          required
        />
        {extraInputs && extraInputs}
        <div className={classes.containerSubmit}>
          <DefaultButton type="submit">
            <FormattedMessage id="MailingForm.submitButton" />
          </DefaultButton>
        </div>
      </form>
    </>
  );
};

MailingForm.propTypes = {
  name: string.isRequired,
  extraInputs: oneOfType([element, arrayOf(element)]),
  extraInputsState: shape(),
};

export default MailingForm;
