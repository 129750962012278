import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ImageIcon from '@material-ui/icons/Image';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AddIcon from '@material-ui/icons/Add';
import { string, func, bool } from 'prop-types';

const imageFormats = ['png', 'jpg', 'jpeg'];
const archiveFormats = ['rar', 'zip'];
const documentFormats = ['doc', 'docx', 'pdf'];

const useStyles = makeStyles({
  Attachment: {
    userDrag: 'none',
    userSelect: 'none',
    cursor: 'pointer',
    '&:hover': {
      '& svg:nth-child(2)': {
        visibility: 'visible',
      },
    },
  },
  iconContainer: {
    width: 'fit-content',
    height: 'fit-content',
    position: 'relative',
    '&>svg:nth-child(1)': {
      fontSize: 75,
      zIndex: 1,
    },
    '&>svg:nth-child(2)': {
      position: 'absolute',
      fontSize: 50,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: 2,
      visibility: 'hidden',
      padding: 1,
      borderRadius: '50%',
      backgroundColor: 'rgba(255,255,255,0.7)',
      backdropFilter: 'blur(5px)',
    },
  },
  deleteIcon: {
    color: '#dc1124',
  },
  addIcon: {
    color: '#0278ae',
  },
  fileName: {
    textOverflow: 'ellipsis',
    textAlign: 'center',
    maxWidth: 80,
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
  },
  image: { color: '#2ec1ac' },
  archive: { color: '#f57b51' },
  document: { color: '#88e1f2' },
  other: { color: '#686d76' },
});

const Attachment = ({
  name,
  onClick,
  asAddFileTrigger,
}) => {
  const classes = useStyles();

  const getIcon = format => {
    if (imageFormats.includes(format)) {
      return <ImageIcon className={classes.image} />;
    } else if (archiveFormats.includes(format)) {
      return <AllInboxIcon className={classes.archive} />;
    } else if (documentFormats.includes(format)) {
      return (
        <DescriptionIcon className={classes.document} />
      );
    } else
      return (
        <InsertDriveFileIcon className={classes.other} />
      );
  };

  const fileFullName = [
    name.slice(0, name.lastIndexOf('.')),

    name.slice(name.lastIndexOf('.') + 1, name.length),
  ];

  return (
    <Grid
      item
      className={classes.Attachment}
      onClick={onClick}
    >
      <Grid item xs={12} className={classes.iconContainer}>
        {getIcon(fileFullName[1])}
        {!asAddFileTrigger ? (
          <DeleteForeverIcon
            className={classes.deleteIcon}
          />
        ) : (
          <AddIcon className={classes.addIcon} />
        )}
      </Grid>
      <Grid item xs={12} className={classes.fileName}>
        {fileFullName[0]}
      </Grid>
    </Grid>
  );
};

Attachment.propTypes = {
  name: string,
  onClick: func,
  asAddFileTrigger: bool,
};
Attachment.defaultProps = {
  name: '',
  onClick: () => {},
  asAddFileTrigger: false,
};
export default Attachment;
